import { Injectable } from "@angular/core";
import { B2bI18nService } from "./b2b-translate.service";

@Injectable({
    providedIn: "root"
})
export class B2bMessageDialogService {

    constructor(private translateService: B2bI18nService) {}

    setMessages(data: any): string[] {
        let messages: string[] = [];
        let msgLanguage: string = ""

        if (localStorage.getItem("language") != null || localStorage.getItem("language") != "") {
            msgLanguage = localStorage.getItem("language") as string;
        } else (
            this.translateService.selectedLanguage$.subscribe((language) => {
                msgLanguage = language
            })
        )

        if (msgLanguage == "it") {
            messages[0] = data.firstMessage.it
            messages[1] = data.secondMessage.it
            if (data.login.boolean === true) {
                messages[2] = "Registrati"
                messages[3] = "Accedi"
            }
        } else {
            messages[0] = data.firstMessage.en
            messages[1] = data.secondMessage.en
            if (data.login.boolean === true) {
                messages[2] = "Register"
                messages[3] = "Login"
            }
        }

        return messages
    }

}